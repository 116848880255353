import React from "react";
import DesktopHomePage from "./DesktopHomePage";
import MobileHomePage from "./MobileHomePage";
import useDeviceDetect from "../useDeviceDetect";

const HomePage = () => {
  let isMobile = useDeviceDetect();
  return <>{!isMobile ? <DesktopHomePage /> : <MobileHomePage />}</>;
};
export default HomePage;
