import styled from "styled-components";

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  width: 100%;
  margin: 0px auto;
  border-radius: 0px 0px 25px 25px;
  position: relative;
  z-index: 0;
  margin-top: -25px;
  background: linear-gradient(
    rgba(56, 42, 67, 0.67) 0%,
    rgba(88, 91, 103, 0.42) 100%
  );
  height: auto;
  min-height: 700px;
  padding-top: 65px;
  padding-bottom: 65px;

  .title {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 42px;
    text-align: center;
    color: #fff;
    width: 100%;
    max-width: 900px;
    margin: 0px auto;
  }
  .subtitle {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    color: #fff;
    width: 100%;
    max-width: 900px;
    margin: 0px auto;
    margin-top: 12px;
  }

  .team-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 900px;
    width: 100%;
    margin: 0px auto;
    margin-top: 50px;
    .team-list {
      margin: 25px 0px;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      li {
        margin-bottom: 10px;
        font-family: "Poppins";
        font-weight: 300;
        font-size: 18px;
        text-align: center;
        color: #fff;
        a {
          text-decoration: none;
          color: #fff;
        }
        span {
          font-family: "Poppins";
          font-weight: 600;
          font-size: 18px;
          text-align: center;
          color: #fff;
          width: 100%;
          max-width: 900px;
          margin: 0px auto;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .team-images {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;
      width: 100%;
      margin: 0px auto;
      border-bottom: 1px solid #fff;
      padding-bottom: 30px;

      .member-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 180px;
        margin: 0px auto;
        h1 {
          font-family: "Poppins";
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          color: #fff;
          width: 100%;
          max-width: 180px;
          margin: 0px auto;
          margin-top: 10px;
        }
        span {
          font-family: "Poppins";
          font-weight: 300;
          font-size: 14px;
          text-align: center;
          color: #fff;
          width: 100%;
          max-width: 180px;
          margin: 0px auto;
          height: 42px;
        }
        .social-links {
          list-style: none;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 180px;
          margin: 0px auto;
          margin-top: 10px;
          li {
            margin-right: 10px;
            &:last-child {
              margin-right: 0px;
            }
            a {
              width: 32px;
              height: 32px;
              border: 1px solid #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              img {
                width: 16px;
                height: 16px;
                object-fit: cover;
              }
            }
          }
        }
        .team-image {
          width: 100%;
          max-width: 120px;
          height: auto;
          max-height: 120px;
          border-radius: 50%;
          overflow: hidden;
          border: 4px solid #fff;
          img {
            height: auto;
            object-fit: contain;
            max-height: 120px;
          }
        }
      }
    }
  }
`;
