import styled from "styled-components";

export const PropContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;

  .item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    img {
      height: 140px;
      object-fit: contain;
      margin-right: 10px;
    }
    p {
      font-family: "Poppins";
      font-weight: 300;
      font-size: 18px;
      color: #e7d9e8;
      span {
        font-family: "Poppins";
        font-weight: 600;
      }
    }
  }
`;
