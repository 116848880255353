import styled from "styled-components";

export const MobilePropContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;

  .item-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    img {
      height: 140px;
      object-fit: contain;
      margin-right: 10px;
    }
    p {
      font-family: "Poppins";
      font-weight: 300;
      font-size: 18px;
      color: #e7d9e8;
      width: 90%;
      max-width: 560px;
      text-align: center;
      span {
        font-family: "Poppins";
        font-weight: 600;
      }
    }
  }
`;
