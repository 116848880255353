import styled from "styled-components";

export const RoadmapImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1180px;
  margin: 0px auto;
  margin-top: 60px;
  margin-bottom: 46px;
  @media (max-width: 768px) {
    margin-top: -100px;
    padding-bottom: 20px;
  }

  h1 {
    font-family: "Poppins";
    font-weight: 800;
    font-size: 30px;
    text-align: center;
    @media (max-width: 768px) {
      display: none;
    }
    color: #da9d31;
  }

  img {
    width: 100%;
    object-fit: cover;
    overflow: show;
    margin-top: -20px;
    margin-bottom: -30px;

    @media (max-width: 768px) {
      margin-top: 0px;
      margin-bottom: -20px;
    }
  }

  .experience-button {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    a {
      padding: 2px 16px;
      border-radius: 40px;
      background: rgba(219, 0, 0, 0.6);
      cursor: pointer;
      text-align: center;
      margin: -4px 80px 0px 0px;
      @media (max-width: 768px) {
        margin: 20px auto 0px auto;
      }

      display: inline-block;
      span {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
`;
