import styled from "styled-components";

export const WhyMobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 768px;
  margin: 0px auto;
  background-color: #392944;
  padding: 40px 0px;
  padding-bottom: 120px;
  .images {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      max-width: 275px;
      border-radius: 10px;
    }
  }
  .info {
    width: 90%;
    margin: 0px 20px;
    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 768px;
      .img {
        max-width: 275px;
        border-radius: 10px;
        margin: 0px auto;
        margin-bottom: 20px;
      }
    }
    h2 {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 40px;
      line-height: 1;
      color: #e7d9e8;
      margin-bottom: 24px;
    }
    p {
      font-family: "Poppins";
      font-weight: 300;
      font-size: 18px;
      line-height: 1.3;
      color: #e7d9e8;
      margin-bottom: 24px;
    }

    ul {
      list-style: none;
      h3 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 18px;
        line-height: 1;
        color: #ee8600;
        margin-bottom: 8px;
      }
      li {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 16px;
        text-align: left;
        color: #e7d9e8;
      }
    }
  }
`;
