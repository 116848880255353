import styled from "styled-components";

export const MobileTeaserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 768px;
  margin: 60px auto;
  img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    z-index: 0;
  }
  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    color: #da9d31;
    margin: 0px auto;
    width: 90%;
    text-align: center;
  }
`;
