import React, { useEffect } from "react";
import { BannerContainer } from "../styles/BannerContainer.styled";
import { PropContainer } from "../styles/PropContainer.styled";
import { TeaserContainer } from "../styles/TeaserConainer.styled";
import { DescriptionContainer } from "../styles/DescriptionContainer.styled";
import { RoadmapContainer } from "../styles/RoadmapContainer.styled";
import { ShowroomContainer } from "../styles/ShowroomContainer.styled";
import { ImageContainer } from "../styles/ImageContainer.styled";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { TokenomyContainer } from "../styles/TokenomyContainer.styled";
import { OneLineContainer } from "../styles/OneLineContainer.styled";
import { TeamContainer } from "../styles/TeamContainer.styled";
import { Footer } from "../styles/Footer.styled";
import { useTranslation, Trans } from "react-i18next";
import { WhyContainer } from "../styles/WhyContainer.styled";
import miniInstagram from "../../src/mini-instagram.svg";
import miniTwitter from "../../src/mini-twitter.svg";
import miniLinkedin from "../../src/mini-linkedin.svg";
import Fade from "react-reveal/Fade";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { RoadmapImageContainer } from "../styles/RoadmapImageContainer";

const DesktopHomePage = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedHatch, setSelectedHatch] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [loadCount, setLoadCount] = React.useState(0);
  const { t } = useTranslation();

  const teamId = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,];
  const hatches = [0, 1, 2, 3];

  const [selectedImage, setSelectedImage] = React.useState(0);
  const tabChange = (tab) => {
    setSelectedTab(tab);
  };

  const hatchChanged = (e) => {
    setSelectedHatch(e);
  };

  const loaded = () => {
    setLoadCount(loadCount + 1);
  };

  useEffect(() => {
    if (loadCount === 4) {
      setLoading(false);
      document.querySelectorAll(
        ".react-transform-wrapper"
      )[0].style.visibility = "visible";
      document.querySelectorAll(".buttons")[0].style.visibility = "visible";
    }
  }, [loadCount]);

  useEffect(() => {
    hatchChanged(0);
    document.querySelectorAll(".react-transform-wrapper").forEach((x) => {
      x.style.visibility = "hidden";
    });
    document.querySelectorAll(".buttons").forEach((x) => {
      x.style.visibility = "hidden";
    });
  }, []);

  const changeImage = (e) => {
    setSelectedImage(e);
  };

  setTimeout(() => {
    if (selectedImage === 3) {
      setSelectedImage(0);
    } else {
      setSelectedImage(selectedImage + 1);
    }
  }, 3000);

  return (
    <>
      <BannerContainer>
        <motion.img
          className="background-image"
          src={"/images/Cover-" + selectedImage + ".jpg"}
          alt="banner"
        />
        <img className="logo" src="/images/logo.png" alt="" />

        <div className="social-links">
          <ul>
            <li>
              <a className="gitbook" href={t("whitepaper")}>
                <img src="/images/gitbook.png" alt="whitepaper" />
                <span>Whitepaper</span>
              </a>
            </li>
            <li>
              <a href="https://discord.gg/thehatchgame">
                <img src="/images/discord-yellow.png" alt="discord" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/thehatchgame">
                <img src="/images/twitter-yellow.png" alt="twitter" />
              </a>
            </li>
          </ul>
          <p className="description">
            <span>{t("description_span")}</span> {t("description")}
          </p>
        </div>

        <h2>
          <span>{t("description_h2_span")}</span> {t("description_h2")}
        </h2>
      </BannerContainer>

      <PropContainer>
        <div className="item-container">
          <img src="/images/NFT-icon.png" alt="prop1" />
          <p>
            <span>{t("prop1")}</span> {t("prop1_span")}
          </p>
        </div>
        <div className="item-container">
          <img src="/images/NFT-icon-1.png" alt="prop2" />
          <p>
            {t("prop2")} <span>{t("prop2_span")}</span> {t("prop2_end")}
          </p>
        </div>
        <div className="item-container">
          <img src="/images/NFT-icon-2.png" alt="prop3" />
          <p>
            <span>{t("prop3_span")}</span> <br /> {t("prop3_end")}
          </p>
        </div>
      </PropContainer>

      <ShowroomContainer>
        <div className="showroom-bg">
          <img className="bg" src="/images/rectangle.png" alt="" />
          {loading ? (
            <>
              <span className="placeholder">4K NFT Loading...</span>
            </>
          ) : null}
          <>
            {hatches.map((x, y) => {
              return (
                <>
                  <TransformWrapper
                    initialScale={1}
                    key={x}
                    id={"transformid" + x}
                    style={{ visibility: "hidden" }}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <>
                        <TransformComponent className="item">
                          <img
                            src={"/images/sr" + x + ".png"}
                            id={"sr" + x}
                            alt=""
                            onLoad={() => {
                              loaded();
                            }}
                          />
                        </TransformComponent>
                        <div
                          className="buttons"
                          style={
                            x === 1
                              ? { visibility: "visible" }
                              : { visibility: "hidden" }
                          }
                        >
                          <button onClick={() => zoomIn()}>+</button>
                          <button onClick={() => zoomOut()}>-</button>
                        </div>
                      </>
                    )}
                  </TransformWrapper>
                </>
              );
            })}
            <motion.div
              className="experience-button"
              whileHover={{
                scale: 1.1,
                transition: {
                  duration: 0.2,
                  ease: "easeInOut",
                },
              }}
            >
              <Link to={`experience/${selectedHatch}`}>
                <span>
                  {" "}
                  <Trans i18nKey={"experience"}></Trans>
                </span>
              </Link>
            </motion.div>
          </>
        </div>

        <div className="info-container">
          <h2 className="title">{t("info_title")}</h2>
          <span className="content">{t("info_description")}</span>

          <div className="image-list">
            <Fade right>
              <ImageContainer
                onClick={() => {
                  hatchChanged(0);
                  document
                    .querySelectorAll(".react-transform-wrapper")
                    .forEach((x) => {
                      x.style.visibility = "hidden";
                    });
                  document.querySelectorAll(
                    ".react-transform-wrapper"
                  )[0].style.visibility = "visible";
                  document.querySelectorAll(".buttons").forEach((x) => {
                    x.style.visibility = "hidden";
                  });
                  document.querySelectorAll(".buttons")[0].style.visibility =
                    "visible";
                }}
              >
                <img
                  src="/images/smini0.png"
                  alt="showroom-1"
                  className={selectedHatch === 0 ? "active-image" : ""}
                />
              </ImageContainer>
            </Fade>
            <Fade right>
              <ImageContainer
                onClick={() => {
                  hatchChanged(1);
                  document
                    .querySelectorAll(".react-transform-wrapper")
                    .forEach((x) => {
                      x.style.visibility = "hidden";
                    });
                  document.querySelectorAll(
                    ".react-transform-wrapper"
                  )[1].style.visibility = "visible";
                  document.querySelectorAll(".buttons").forEach((x) => {
                    x.style.visibility = "hidden";
                  });
                  document.querySelectorAll(".buttons")[1].style.visibility =
                    "visible";
                }}
              >
                <img
                  src="/images/smini1.png"
                  alt="showroom-1"
                  className={selectedHatch === 1 ? "active-image" : ""}
                />
              </ImageContainer>
            </Fade>
            <Fade right>
              <ImageContainer
                onClick={() => {
                  hatchChanged(2);
                  document
                    .querySelectorAll(".react-transform-wrapper")
                    .forEach((x) => {
                      x.style.visibility = "hidden";
                    });
                  document.querySelectorAll(
                    ".react-transform-wrapper"
                  )[2].style.visibility = "visible";
                  document.querySelectorAll(".buttons").forEach((x) => {
                    x.style.visibility = "hidden";
                  });
                  document.querySelectorAll(".buttons")[2].style.visibility =
                    "visible";
                }}
              >
                <img
                  src="/images/smini2.jpg"
                  alt="showroom-1"
                  className={selectedHatch === 2 ? "active-image" : ""}
                />
              </ImageContainer>
            </Fade>

            <Fade right>
              <ImageContainer
                onClick={() => {
                  hatchChanged(3);
                  document
                    .querySelectorAll(".react-transform-wrapper")
                    .forEach((x) => {
                      x.style.visibility = "hidden";
                    });
                  document.querySelectorAll(
                    ".react-transform-wrapper"
                  )[3].style.visibility = "visible";
                  document.querySelectorAll(".buttons").forEach((x) => {
                    x.style.visibility = "hidden";
                  });
                  document.querySelectorAll(".buttons")[3].style.visibility =
                    "visible";
                }}
              >
                <img
                  src="/images/smini3.jpg"
                  alt="showroom-1"
                  className={selectedHatch === 3 ? "active-image" : ""}
                />
              </ImageContainer>
            </Fade>
          </div>
        </div>
      </ShowroomContainer>

      <TeaserContainer>
        <img src="/images/teaser-back.png" alt="teaser" />
        <a href="https://www.youtube.com/watch?v=egUKIuU9eCc" target="_blank">
          <h2>{t("watch_teaser")}</h2>
        </a>
      </TeaserContainer>

      <DescriptionContainer>
        <ul>
          <Fade left>
            <li>
              <img src="/images/NFT-icon.png" alt="description 1" />
              <h2>{t("def1")}</h2>
              <span>
                {" "}
                <Trans i18nKey={"def1_text"}></Trans>
              </span>
            </li>
          </Fade>
          <Fade bottom>
            <li>
              <img src="/images/NFT-icon-1.png" alt="description 2" />
              <h2>{t("def2")}</h2>
              <span>
                <Trans i18nKey={"def2_text"}></Trans>
              </span>
            </li>
          </Fade>
          <Fade right>
            <li>
              <img src="/images/NFT-icon-2.png" alt="description 3" />
              <h2>{t("def3")}</h2>
              <span>
                <Trans i18nKey={"def3_text"}></Trans>
              </span>
            </li>
          </Fade>
        </ul>
      </DescriptionContainer>

      <WhyContainer>
        <div className="info">
          <h2 className="title">
            <Trans i18nKey={"why_title"}></Trans>
          </h2>
          <p className="text">
            {" "}
            <Trans i18nKey={"why_text"}></Trans>
          </p>
          <ul>
            <h3>
              {" "}
              <Trans i18nKey={"why_list_title"}></Trans>
            </h3>
            <li>
              {" "}
              <Trans i18nKey={"why_list_1"}></Trans>
            </li>
            <li>
              {" "}
              <Trans i18nKey={"why_list_2"}></Trans>
            </li>
            <li>
              {" "}
              <Trans i18nKey={"why_list_3"}></Trans>
            </li>
            <li>
              {" "}
              <Trans i18nKey={"why_list_4"}></Trans>
            </li>
            <li>
              {" "}
              <Trans i18nKey={"why_list_5"}></Trans>
            </li>

          </ul>
        </div>
        <Fade right cascade>
          <div className="images">
            <img src="/images/smini4.jpg" alt="" />
            <img src="/images/smini3.jpg" alt="" />
          </div>
        </Fade>
      </WhyContainer>

      <RoadmapImageContainer>
        <h1>
          <Trans i18nKey={"r_image_title"}></Trans>
        </h1>
        <img src="/images/roadmap.png" alt="roadmap" />
        <motion.div className="experience-button">
          <a href={`images/roadmap4k.png`}>
            <span>
              {" "}
              <Trans i18nKey={"review"}></Trans>
            </span>
          </a>
        </motion.div>
      </RoadmapImageContainer>

      <Fade bottom>
        <TokenomyContainer>
          <div className="col-2">
            <ul>
              <li>
                <img src="/images/t_h.png" alt="Hatch token" />
                <span>HATCH</span>
              </li>
            </ul>
            <h2>
              <Trans i18nKey={"tokenomy_title"}></Trans>
            </h2>
          </div>
          <span>
            <Trans i18nKey={"tokenomy_text"}></Trans>
          </span>
          <h3>
            <a href={t("whitepaper")}>
              {" "}
              <Trans i18nKey={"tokenomy_subtitle"}></Trans>
            </a>
          </h3>
          <img className="avax" src="/images/avax.png" alt="avax" />
        </TokenomyContainer>
      </Fade>

      <RoadmapContainer>
        <div className="title-container">
          <h2>
            <Trans i18nKey={"roadmap_title"}></Trans>
          </h2>
          <span>
            <Trans i18nKey={"roadmap_version"}></Trans>
          </span>
        </div>

        <div className="tab-container">
          <div
            className={selectedTab === 0 ? "active-circle" : "circle"}
            onClick={() => tabChange(0)}
          >
            <img src="/images/border.png" alt="" />
          </div>
          <div
            className={selectedTab === 1 ? "active-circle" : "circle"}
            onClick={() => tabChange(1)}
          >
            <img src="/images/border.png " alt="" />
          </div>
          <div
            className={selectedTab === 2 ? "active-circle" : "circle"}
            onClick={() => tabChange(2)}
          >
            <img src="/images/border.png" alt="" />
          </div>
          <div
            className={selectedTab === 3 ? "active-circle" : "circle"}
            onClick={() => tabChange(3)}
          >
            <img src="/images/border.png" alt="" />
          </div>

        </div>

        <div className="text-tab-container">
          <span className={selectedTab === 0 ? "active" : "notactive"}>
            2023 Q2{" "}
          </span>
          <span className={selectedTab === 1 ? "active" : "notactive"}>
            2023 Q3{" "}
          </span>
          <span className={selectedTab === 2 ? "active" : "notactive"}>
            2023 Q4{" "}
          </span>
          <span className={selectedTab === 3 ? "active" : "notactive"}>
            2024 Q1{" "}
          </span>
        </div>

        <div className="content-container">
          {selectedTab === 0 ? (
            <Fade bottom>
              <div>
                <h2>
                  <Trans i18nKey={"roadmap_1_title"}></Trans>
                </h2>
                <span>
                  <Trans i18nKey={"roadmap_1_text"}></Trans>
                </span>
              </div>
            </Fade>
          ) : (
            ""
          )}
          {selectedTab === 1 ? (
            <Fade bottom>
              <div>
                <h2>
                  <Trans i18nKey={"roadmap_2_title"}></Trans>
                </h2>
                <span>
                  <Trans i18nKey={"roadmap_2_text"}></Trans>
                </span>
              </div>
            </Fade>
          ) : (
            ""
          )}
          {selectedTab === 2 ? (
            <Fade bottom>
              <div>
                <h2>
                  <Trans i18nKey={"roadmap_3_title"}></Trans>
                </h2>
                <span>
                  <Trans i18nKey={"roadmap_3_text"}></Trans>
                </span>
              </div>
            </Fade>
          ) : (
            ""
          )}
          {selectedTab === 3 ? (
            <Fade bottom>
              <div>
                <h2>
                  <Trans i18nKey={"roadmap_4_title"}></Trans>
                </h2>
                <span>
                  <Trans i18nKey={"roadmap_4_text"}></Trans>
                </span>
              </div>
            </Fade>
          ) : (
            ""
          )}

        </div>
      </RoadmapContainer>

      <OneLineContainer>
        <h1 className="title">F.A.Q.</h1>
        <p className="text">
          <Trans i18nKey={"oneline_text"}></Trans>
        </p>
        <ul className="social-links">
          <li>
            <a href="https://discord.gg/thehatchgame">
              <img src="/images/discord-red.png" alt="discord hatch" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/thehatchgame">
              <img src="/images/twitter-red.png" alt="twitter hatch" />
            </a>
          </li>
        </ul>
      </OneLineContainer>

      <TeamContainer>
        <span className="title">
          {" "}
          <Trans i18nKey={"team_title"}></Trans>
        </span>
        <span className="subtitle">
          <Trans i18nKey={"team_text"}></Trans>
        </span>
        <div className="team-container">
          <div className="team-images">
            <div className="member-container">
              <div className="team-image">
                <img src="/images/c1.jpg" alt="" />
              </div>
              <h1>{t("team_member_1")}</h1>
              <span>{t("team_member_1_sub")}</span>
              <ul className="social-links">
                <li>
                  <a href={t("team_member_1_link")}>
                    <img src={miniTwitter} alt="discord hatch" />
                  </a>
                </li>
                <li>
                  <a href={t("team_member_1_link_2")}>
                    <img src={miniInstagram} alt="discord hatch" />
                  </a>
                </li>
                <li>
                  <a href={t("team_member_1_link_3")}>
                    <img src={miniLinkedin} alt="discord hatch" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="member-container">
              <div className="team-image">
                <img src="/images/r1.png" alt="" />
              </div>
              <h1>{t("team_member_2")}</h1>
              <span>{t("team_member_2_sub")}</span>
              <ul className="social-links">
                <li>
                  <a href={t("team_member_2_link")}>
                    <img src={miniTwitter} alt="discord hatch" />
                  </a>
                </li>
                <li>
                  <a href={t("team_member_2_link_2")}>
                    <img src={miniInstagram} alt="discord hatch" />
                  </a>
                </li>
                <li>
                  <a href={t("team_member_2_link_3")}>
                    <img src={miniLinkedin} alt="discord hatch" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="member-container">
              <div className="team-image">
                <img src="/images/k1.png" alt="" />
              </div>
              <h1>{t("team_member_3")}</h1>
              <span>{t("team_member_3_sub")}</span>
              <ul className="social-links">
                <li>
                  <a href={t("team_member_3_link_2")}>
                    <img src={miniInstagram} alt="linkedin hatch" />
                  </a>
                </li>
                <li>
                  <a href={t("team_member_3_link")}>
                    <img src={miniLinkedin} alt="discord hatch" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="member-container">
              <div className="team-image">
                <img src="/images/g1.png" alt="" />
              </div>
              <h1>{t("team_member_4")}</h1>
              <span>{t("team_member_4_sub")}</span>
              <ul className="social-links">
                <li>
                  <a href={t("team_member_4_link")}>
                    <img src={miniTwitter} alt="discord hatch" />
                  </a>
                </li>
                <li>
                  <a href={t("team_member_4_link_2")}>
                    <img src={miniInstagram} alt="discord hatch" />
                  </a>
                </li>
                <li>
                  <a href={t("team_member_4_link_3")}>
                    <img src={miniLinkedin} alt="discord hatch" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <ul className="team-list">
            {teamId.map((id, index) => (
              <li>
                <a href={t("team_member_" + id + "_link")}>
                  {" "}
                  <span>{t("team_member_" + id)} </span> /{" "}
                  {t("team_member_" + id + "_sub")}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </TeamContainer>

      <Footer>
        <p>COPYRIGHT © 2022</p>
        <h2>Labs 2616</h2>
      </Footer>
    </>
  );
};
export default DesktopHomePage;

/* 
      <TeaserContainer>
        <img src="/images/teaser-back.png" alt="teaser" />
        <h2>{t("watch_teaser")}</h2>
      </TeaserContainer>
*/
