import styled from "styled-components";

export const MobileRoadmapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  justify-content: flex-start;
  width: 90%;
  max-width: 768px;
  margin: 0px auto 20px auto;
  z-index: 0;
  .title-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    h2 {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 28px;
      color: #e2d2e1;
      text-align: center;
      text-transform: uppercase;
      margin-right: 14px;
    }
    span {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 24px;
      color: #e2d2e1;
      text-transform: uppercase;
    }
  }

  .dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 768px;
    height: 60px;
    margin: 10px auto 0px auto;
    .times-dropdown {
      width: 90% !important;
      background: #000;
      border: 2px solid #322429;
      outline: none;
      border-radius: 10px;
      padding: 10px;
      color: #e7d9e8;
      font-weight: 600;
      font-family: "Poppins";
    }
  }

  .tab-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 2px solid rgba(220, 14, 11, 0.31);
    height: 40px;
    border-radius: 20px;
    margin: 24px 0px 0px 0px;
    padding: 10px 8px;
    .circle {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      background: rgba(103, 72, 82, 0.49);
      cursor: pointer;
      transition: all 1s ease;
      position: relative;
      img {
        display: none;
        position: absolute;
        top: -18px;
        left: -14px;
        width: 52px;
      }
    }
    .active-circle {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      cursor: pointer;
      background: radial-gradient(circle at center, #e6a486 0, #db0000 100%);
      position: relative;
      img {
        position: absolute;
        top: -18px;
        left: -14px;
        width: 52px;
      }
    }
  }

  .text-tab-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;

    .notactive {
      font-size: 12px;
      font-family: "Poppins" !important;
      font-weight: 600;
      text-align: center;
      color: #3c3e47;
    }
    .active {
      color: #e7d9e8;
      font-size: 12px;
      text-align: center;
      font-family: "Poppins" !important;
      font-weight: 600;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: linear-gradient(rgba(103, 72, 82, 0.49) 0%, #0b090a 100%);
    width: 100%;
    max-width: 1024px;
    border-radius: 25px 25px 0px 0px;
    height: auto;
    padding: 20px;
    padding-bottom: 40px;
    margin: 24px auto;
    min-height: 320px;
    height: auto;
    h2 {
      font-size: 22px;
      line-height: 30px;
      text-align: left;
      color: #e7d9e8;
      font-family: "Poppins";
      font-weight: 600;
      margin-bottom: 18px;
    }
    span {
      font-size: 18px;
      text-align: left;
      color: #e7d9e8;
      font-family: "Poppins";
      font-weight: 300;
    }
  }
`;
