import React from "react";
import { MobileBannerContainer } from "../styles/MobileBannerContainer.styled";
import { MobileContainer } from "../styles/MobileContainer.styled";
import { MobilePropContainer } from "../styles/MobilePropContainer.styled";
import { MobileTeaserContainer } from "../styles/MobileTeaserContainer.styled";
import { MobileDescriptionContainer } from "../styles/MobileDescriptionContainer.styled";
import { MobileTokenomyContainer } from "../styles/MobileTokenomyContainer.styled";
import { MobileShowroomContainer } from "../styles/MobileShowroomContainer.styled";

import { useTranslation, Trans } from "react-i18next";
import { MobileOneLineContainer } from "../styles/MobileOneLineContainer.styled";
import { MobileTeamContainer } from "../styles/MobileTeamContainer.styled";
import { MobileRoadmapContainer } from "../styles/MobileRoadmapContainer.styled";
import { Footer } from "../styles/Footer.styled";
import { WhyMobileContainer } from "../styles/WhyMobileContainer.styled";
import miniInstagram from "../../src/mini-instagram.svg";
import miniTwitter from "../../src/mini-twitter.svg";
import miniLinkedin from "../../src/mini-linkedin.svg";
import { RoadmapImageContainer } from "../styles/RoadmapImageContainer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const MobileHomePage = () => {
  const { t } = useTranslation();
  const teamId = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [selectedImage, setSelectedImage] = React.useState(0);

  const loaded = () => {
    setLoading(false);
  };

  const changeImage = (e) => {
    setSelectedImage(e);
  };

  setTimeout(() => {
    if (selectedImage === 3) {
      setSelectedImage(0);
    } else {
      setSelectedImage(selectedImage + 1);
    }
  }, 3000);

  return (
    <>
      <MobileContainer>
        <MobileBannerContainer>
          <img
            className="background-image"
            src={"/images/Cover-" + selectedImage + ".jpg"}
            alt="banner"
          />
          <img className="logo" src="/images/mobile-logo.png" />
          <h2>
            <span>{t("description_h2_span")}</span> {t("description_h2")}
          </h2>
          <div className="social-links">
            <ul>
              <li>
                <a className="gitbook" href={t("whitepaper")}>
                  <img src="/images/gitbook.png" alt="whitepaper" />
                  <span>Whitepaper</span>
                </a>
              </li>
              <li>
                <a href="https://discord.gg/thehatchgame">
                  <img src="/images/discord-yellow.png" alt="discord" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/thehatchgame">
                  <img src="/images/twitter-yellow.png" alt="twitter" />
                </a>
              </li>
            </ul>
          </div>
        </MobileBannerContainer>
        <MobilePropContainer>
          <div className="item-container">
            <img src="/images/NFT-icon.png" alt="prop1" />
            <p>
              <span>{t("prop1")}</span> {t("prop1_span")}
            </p>
          </div>
          <div className="item-container">
            <img src="/images/NFT-icon-1.png" alt="prop2" />
            <p>
              {t("prop2")} <span>{t("prop2_span")}</span> {t("prop2_end")}
            </p>
          </div>
          <div className="item-container">
            <img src="/images/NFT-icon-2.png" alt="prop3" />
            <p>
              {t("prop3")} <br /> <span>{t("prop3_span")}</span>{" "}
              {t("prop3_end")}
            </p>
          </div>
        </MobilePropContainer>

        <MobileShowroomContainer>
          <div className="showroom">
            <img className="bg" src="/images/rectangle.png" />
            {loading ? (
              <>
                <span className="placeholder">4K NFT Loading...</span>
              </>
            ) : null}
            <img
              className="hatch"
              src="/images/sr1mini.png"
              style={
                loading ? { visibility: "hidden" } : { visibility: "visible" }
              }
              onLoad={() => {
                loaded();
              }}
            />
            <a className="open-title" href="/images/sr1mini.png">
              {" "}
              {t("open_text")}
            </a>
          </div>

          <h2 className="title">{t("info_title")}</h2>
          <p className="text">{t("info_description")}</p>
        </MobileShowroomContainer>

        <MobileTeaserContainer>
          <img src="/images/teaser-back.png" alt="teaser" />
          <a href="https://www.youtube.com/watch?v=egUKIuU9eCc" target="_blank">
            <h2>{t("watch_teaser")}</h2>
          </a>
        </MobileTeaserContainer>

        <MobileDescriptionContainer>
          <ul>
            <li>
              <img src="/images/NFT-icon.png" alt="description 1" />
              <h2>{t("def1")}</h2>
              <span>
                {" "}
                <Trans i18nKey={"def1_text"}></Trans>
              </span>
            </li>
            <li>
              <img src="/images/NFT-icon-1.png" alt="description 2" />
              <h2>{t("def2")}</h2>
              <span>
                <Trans i18nKey={"def2_text"}></Trans>
              </span>
            </li>
            <li>
              <img src="/images/NFT-icon-2.png" alt="description 3" />
              <h2>{t("def3")}</h2>
              <span>
                <Trans i18nKey={"def3_text"}></Trans>
              </span>
            </li>
          </ul>
        </MobileDescriptionContainer>

        <WhyMobileContainer>
          <div className="info">
            <h2 className="title">
              <Trans i18nKey={"why_title"}></Trans>
            </h2>

            <div className="img-container">
              <img className="img" src="/images/smini4.jpg" />
            </div>

            <p className="text">
              {" "}
              <Trans i18nKey={"why_text"}></Trans>
            </p>
            <ul>
              <h3>
                {" "}
                <Trans i18nKey={"why_list_title"}></Trans>
              </h3>
              <li>
                {" "}
                <Trans i18nKey={"why_list_1"}></Trans>
              </li>
              <li>
                {" "}
                <Trans i18nKey={"why_list_2"}></Trans>
              </li>
              <li>
                {" "}
                <Trans i18nKey={"why_list_3"}></Trans>
              </li>
              <li>
                {" "}
                <Trans i18nKey={"why_list_4"}></Trans>
              </li>
              <li>
                {" "}
                <Trans i18nKey={"why_list_5"}></Trans>
              </li>
            </ul>
          </div>
        </WhyMobileContainer>

        <RoadmapImageContainer>
          <h1>
            <Trans i18nKey={"r_image_title"}></Trans>
          </h1>
          <img src="/images/roadmap.png" alt="roadmap" />
          <div className="experience-button">
            <a href={`images/roadmap4k.png`}>
              <span>
                {" "}
                <Trans i18nKey={"review"}></Trans>
              </span>
            </a>
          </div>
        </RoadmapImageContainer>
        <MobileTokenomyContainer>
          <ul>
            <li>
              <img src="/images/t_h.png" alt="Hatch token" />
              <span>HATCH</span>
            </li>
          </ul>
          <img className="avax" src="/images/avax.png" alt="avax" />
          <h2>
            <Trans i18nKey={"tokenomy_title"}></Trans>
          </h2>
          <span>
            <Trans i18nKey={"tokenomy_text"}></Trans>
          </span>
          <h3>
            <Trans i18nKey={"tokenomy_subtitle"}></Trans>
          </h3>
        </MobileTokenomyContainer>

        <MobileRoadmapContainer>
          <div className="title-container">
            <h2>
              <Trans i18nKey={"roadmap_title"}></Trans>
            </h2>
            <span>
              <Trans i18nKey={"roadmap_version"}></Trans>
            </span>
          </div>

          <div className="dropdown">
            <select
              className="times-dropdown"
              name="times"
              id="times"
              onChange={(e) => {
                setSelectedTab(parseInt(e.target.value));
              }}
            >
              <option value="0">2023 Q2</option>
              <option value="1">2023 Q3</option>
              <option value="2">2023 Q4</option>
              <option value="3">2024 Q1</option>
            </select>
          </div>

          <div className="content-container">
            {selectedTab === 0 ? (
              <div>
                <h2>
                  <Trans i18nKey={"roadmap_1_title"}></Trans>
                </h2>
                <span>
                  <Trans i18nKey={"roadmap_1_text"}></Trans>
                </span>
              </div>
            ) : (
              ""
            )}
            {selectedTab === 1 ? (
              <div>
                <h2>
                  <Trans i18nKey={"roadmap_2_title"}></Trans>
                </h2>
                <span>
                  <Trans i18nKey={"roadmap_2_text"}></Trans>
                </span>
              </div>
            ) : (
              ""
            )}
            {selectedTab === 2 ? (
              <div>
                <h2>
                  <Trans i18nKey={"roadmap_3_title"}></Trans>
                </h2>
                <span>
                  <Trans i18nKey={"roadmap_3_text"}></Trans>
                </span>
              </div>
            ) : (
              ""
            )}
            {selectedTab === 3 ? (
              <div>
                <h2>
                  <Trans i18nKey={"roadmap_4_title"}></Trans>
                </h2>
                <span>
                  <Trans i18nKey={"roadmap_4_text"}></Trans>
                </span>
              </div>
            ) : (
              ""
            )}

          </div>
        </MobileRoadmapContainer>

        <MobileOneLineContainer>
          <h1 className="title">F.A.Q.</h1>
          <p className="text">
            <Trans i18nKey={"oneline_text"}></Trans>
          </p>
          <ul className="social-links">
            <li>
              <a href="https://twitter.com/thehatchgame">
                <img src="/images/twitter-red.png" alt="twitter hatch" />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/thehatchgame">
                <img src="/images/discord-red.png" alt="discord hatch" />
              </a>
            </li>
          </ul>
        </MobileOneLineContainer>

        <MobileTeamContainer>
          <span className="title">
            <Trans i18nKey={"team_title"}></Trans>
          </span>
          <span className="subtitle">
            <Trans i18nKey={"team_text"}></Trans>
          </span>
          <div className="team-container">
            <div className="team-images">
              <div className="member-container">
                <div className="team-image">
                  <img src="/images/c1.jpg" />
                </div>
                <h1>{t("team_member_1")}</h1>
                <span>{t("team_member_1_sub")}</span>
                <ul className="social-links">
                  <li>
                    <a href={t("team_member_1_link")}>
                      <img src={miniTwitter} alt="discord hatch" />
                    </a>
                  </li>
                  <li>
                    <a href={t("team_member_1_link_2")}>
                      <img src={miniInstagram} alt="discord hatch" />
                    </a>
                  </li>
                  <li>
                    <a href={t("team_member_1_link_3")}>
                      <img src={miniLinkedin} alt="discord hatch" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="member-container">
                <div className="team-image">
                  <img src="/images/r1.png" />
                </div>
                <h1>{t("team_member_2")}</h1>
                <span>{t("team_member_2_sub")}</span>
                <ul className="social-links">
                  <li>
                    <a href={t("team_member_2_link")}>
                      <img src={miniTwitter} alt="discord hatch" />
                    </a>
                  </li>
                  <li>
                    <a href={t("team_member_2_link_2")}>
                      <img src={miniInstagram} alt="discord hatch" />
                    </a>
                  </li>
                  <li>
                    <a href={t("team_member_2_link_3")}>
                      <img src={miniLinkedin} alt="discord hatch" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="member-container">
                <div className="team-image">
                  <img src="/images/k1.png" />
                </div>
                <h1>{t("team_member_3")}</h1>
                <span>{t("team_member_3_sub")}</span>
                <ul className="social-links">
                  <li>
                    <a href={t("team_member_3_link_2")}>
                      <img src={miniInstagram} alt="linkedin hatch" />
                    </a>
                  </li>
                  <li>
                    <a href={t("team_member_3_link")}>
                      <img src={miniLinkedin} alt="discord hatch" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="member-container">
                <div className="team-image">
                  <img src="/images/g1.png" />
                </div>
                <h1>{t("team_member_4")}</h1>
                <span>{t("team_member_4_sub")}</span>
                <ul className="social-links">
                  <li>
                    <a href={t("team_member_4_link")}>
                      <img src={miniTwitter} alt="discord hatch" />
                    </a>
                  </li>
                  <li>
                    <a href={t("team_member_4_link_2")}>
                      <img src={miniInstagram} alt="discord hatch" />
                    </a>
                  </li>
                  <li>
                    <a href={t("team_member_4_link_3")}>
                      <img src={miniLinkedin} alt="discord hatch" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <ul className="team-list">
              {teamId.map((id, index) => (
                <li>
                  <a href={t("team_member_" + id + "_link")}>
                    {" "}
                    <span>{t("team_member_" + id)} </span> /{" "}
                    {t("team_member_" + id + "_sub")}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </MobileTeamContainer>
        <Footer>
          <p>COPYRIGHT © 2022</p>
          <h2>Labs 2616</h2>
        </Footer>
      </MobileContainer>
    </>
  );
};
export default MobileHomePage;

/*
 <MobileTeaserContainer>
          <img src="/images/teaser-back.png" alt="teaser" />
          <h2>Watch Teaser</h2>
        </MobileTeaserContainer>
*/
