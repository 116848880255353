import styled from "styled-components";

export const ExperienceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  background-color: transparent;
  position: relative;
  .loading-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: #da9d31;
    h1 {
      font-weight: bold;
    }

    .loading-gif {
      width: 5%;
      object-fit: cover;
      margin-top: -12px;
    }
  }
  .react-transform-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    cursor: grab;

    .react-transform-component {
      width: 100% !important;
    }
    @media (max-width: 768px) {
      height: fit-content;
      min-height: 100vh;
    }

    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      margin: 0px auto;

      @media (max-width: 768px) {
        width: 100%;
        height: fit-content;
        object-fit: cover;
        margin: 0px auto;
      }
    }
  }
`;
